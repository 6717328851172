import { Modal } from 'antd';
import { IUser } from 'config/interfaces';
import AccountNotificationActionsTelNumber from './account-notification-actions-telephone';

type Props = {
    children: JSX.Element;
    self: IUser | null;
}

const AccountNotificationActionsProvider = ({ children, self }: Props): JSX.Element => {

    if (!self) return children;
    if (self && self.alertification.length === 0) return children;

    return (
        <>
            <Modal
                width={800}
                open={true}
                footer={null}
                closable={false}
                destroyOnClose={true}
            >
                <AccountNotificationActionsTelNumber id={self?.alertification[0].phone_number?.id!} />
            </Modal>
            <>
                {children}
            </>
        </>
    )
}

export default AccountNotificationActionsProvider;