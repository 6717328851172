import { memo } from "react";
import { ExpandOutlined, FilePdfOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IJob } from "config/interfaces";
import { TaskItemDetailPerObject, TaskItemDetailTitle } from "components/task";
import { DISUSSION_ICON } from "utils";
import { ROUTES } from "config/enums";
import { SOCIAL_LINKS } from "config/config";

interface IProps {
    onLinkClick: () => void;
    current_job: IJob;
    jobName: IJob[];
}

const TaskItemDetailInformation = ({
    jobName,
    current_job,
    onLinkClick,
}: IProps): JSX.Element => {
    const { t } = useTranslation();
    const NAME = jobName[0]?.name;

    return (
        <>
            <TaskItemDetailTitle
                name={NAME}
                reward_per_object={current_job.reward_per_object}
            />
            <div className="job_detail_item">
                <ExpandOutlined />
                <p>{t("JOB.DETAIL.FRAMES")}</p>
                <b>{current_job.frames_qty}</b>
            </div>
            {current_job.reward_per_object && (
                <TaskItemDetailPerObject
                    reward_per_object={current_job.reward_per_object}
                />
            )}
            <div className="job_detail_item">
                <FilePdfOutlined />
                <p>{t("JOB.DETAIL.LINK")}</p>
                {current_job.guideline_link && (
                    <a
                        data-cy="instruction_link"
                        style={{ color: "#00c17c" }}
                        onClick={onLinkClick}
                        href={
                            current_job.draft
                                ? SOCIAL_LINKS.youtube
                                : `${ROUTES.PROJECT_INSTRUCTION}${current_job.project_id}`
                        }
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("CHECK_INSTRUCTION")}
                    </a>
                )}
            </div>
            {current_job.discussion_link && (
                <div className="job_detail_item">
                    <DISUSSION_ICON />
                    <p>{t("DISCUSSION")}:</p>
                    <a
                        style={{ color: "#00c17c" }}
                        onClick={onLinkClick}
                        href={current_job.discussion_link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {t("DISCUSSION_IN", { item: "discord" })}
                    </a>
                </div>
            )}
        </>
    );
};

export default memo(TaskItemDetailInformation);
