import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { STATUS_WITHDRAWAL } from "../../config/enums";
import { IBalance, ITransaction, ISendMyMoneyResponse, IPayoutSettings, IPayoutState, IMyEarningResponse, IFee } from '../../config/interfaces';
import {
    getBalanceAsync,
    getMyMoneyAsync,
    sendMyMoneyAsync,
    payoutConfirmationAsync,
    payoutCancelAsync,
    getPayoutSettingsAsync,
} from './payout-actions';
import { Mixpanel, MIXPANEL_TRACK_KEYS } from "services/mixpanel";



const INITIAL_STATE: IPayoutState = {
    loading: false,
    error: null,
    balance: {} as IBalance,
    balance_loading: false,
    transaction_success: 'default',
    transaction_loading: false,
    transaction_error: null,
    transaction_current: null,
    transaction_code_success: false,
    payout_settings: {
        bank_options: {},
        recipient_accounts: {},
        commissions: {
            payoneer: {} as IFee,
            wise: {} as IFee,
            swift: {} as IFee,
        }
    },
    transaction_data: {
        count: 0,
        next: null,
        previous: null,
        results: []
    },
    pagination_page: 1
}

export const payoutSlice = createSlice({
    name: 'account',
    initialState: INITIAL_STATE,
    reducers: {
        clearErrorPayoutReducer(state) {
            state.error = null
        },
        setLoadingPayoutReducer(state, action: PayloadAction<boolean>) {
            state.loading = action.payload
        },
        addCurrentTransaction(state, action: PayloadAction<number>) {
            state.transaction_current = state.transaction_data.results.find((el: ITransaction) => el.id === action.payload) ?? null;
        },
        clearCurrentTransaction(state) {
            state.transaction_current = null;
        },
        set_default_transaction_success(state) {
            state.transaction_success = 'default'
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBalanceAsync.pending, (state) => {
                state.balance_loading = true;
            })
            .addCase(getBalanceAsync.fulfilled, (state, action: PayloadAction<IBalance>) => {
                state.balance_loading = false;
                state.balance = action.payload;
            })
            .addCase(getBalanceAsync.rejected, (state) => {
                state.balance_loading = false;
            })
            .addCase(getMyMoneyAsync.pending, (state) => {
                // state.loading = true;
                state.error = null;
            })
            .addCase(getMyMoneyAsync.fulfilled, (state, action: PayloadAction<IMyEarningResponse>) => {
                // state.loading = false;
                state.transaction_data = action.payload;
            })
            .addCase(getMyMoneyAsync.rejected, (state) => {
                // state.loading = false;
            })
            .addCase(sendMyMoneyAsync.pending, (state) => {
                state.transaction_loading = true;
                state.transaction_error = null;
                state.transaction_success = 'default';
            })
            .addCase(sendMyMoneyAsync.fulfilled, (state, action: PayloadAction<ISendMyMoneyResponse>) => {
                state.transaction_loading = false;
                state.transaction_error = null;
                state.transaction_success =
                    action.payload.payout.status === STATUS_WITHDRAWAL.DECLINED
                        ? STATUS_WITHDRAWAL.DECLINED
                        : 'success';
                state.balance = action.payload.balance;
                state.transaction_data = {
                    ...state.transaction_data,
                    results: [action.payload.payout, ...state.transaction_data.results],
                };
                state.transaction_current = action.payload.payout;
            })
            .addCase(sendMyMoneyAsync.rejected, (state, action: PayloadAction<any>) => {
                state.transaction_loading = false;
                state.transaction_success = 'default';
                state.transaction_error = action.payload;
            })
            .addCase(payoutConfirmationAsync.fulfilled, (state, action: PayloadAction<ITransaction>) => {
                state.transaction_code_success = true;
                if (state.transaction_current) {
                    Mixpanel.track(MIXPANEL_TRACK_KEYS.PAYOUT_REQUESTS, {
                        wise_recipient_account: state.transaction_current.wise_recipient_account,
                        sum: state.transaction_current.amount
                    })
                    state.balance = {
                        ...state.balance,
                        payouts_pending: state.balance.payouts_pending - state.transaction_current.amount,
                        payouts_waiting: state.balance.payouts_waiting + state.transaction_current.amount,
                    };
                    let newResult = {
                        ...state.transaction_current,
                        status: STATUS_WITHDRAWAL.WAITING,
                    };
                    state.transaction_current = newResult;
                    state.transaction_data.results = state.transaction_data.results.map((el: ITransaction) => {
                        if (state.transaction_current && state.transaction_current.id === el.id) {
                            return newResult;
                        }
                        return el;
                    });
                }
            })
            .addCase(payoutConfirmationAsync.rejected, (state) => {
                state.transaction_code_success = false;
                state.transaction_success = 'default';
            })
            .addCase(payoutCancelAsync.pending, (state) => {
                state.transaction_loading = true;
            })
            .addCase(payoutCancelAsync.fulfilled, (state, action: PayloadAction<ITransaction>) => {
                state.transaction_loading = false;
                state.transaction_success = 'default';
                state.transaction_code_success = false;

                if (state.transaction_current) {
                    let newTransactionData: ITransaction = { ...state.transaction_current, status: STATUS_WITHDRAWAL.CANCELLED };

                    state.balance = {
                        ...state.balance,
                        balance: state.balance.balance + state.transaction_current.amount,
                        payouts_waiting:
                            state.transaction_current.status === STATUS_WITHDRAWAL.WAITING
                                ? state.balance.payouts_waiting - state.transaction_current.amount
                                : state.balance.payouts_waiting,
                        payouts_pending:
                            state.transaction_current.status === STATUS_WITHDRAWAL.PENDING
                                ? state.balance.payouts_pending - state.transaction_current.amount
                                : state.balance.payouts_pending,
                    };

                    state.transaction_current = newTransactionData;
                    state.transaction_data.results = state.transaction_data.results.map((el: ITransaction) => {
                        if (state.transaction_current && state.transaction_current.id === el.id) {
                            return newTransactionData;
                        }
                        return el;
                    });
                }
            })
            .addCase(payoutCancelAsync.rejected, (state) => {
                state.transaction_loading = false;
            })
            .addCase(getPayoutSettingsAsync.fulfilled, (state, action: PayloadAction<IPayoutSettings>) => {
                state.payout_settings = action.payload;
            })
    }
})

export const {
    clearErrorPayoutReducer,
    setLoadingPayoutReducer,
    addCurrentTransaction,
    clearCurrentTransaction,
    set_default_transaction_success
} = payoutSlice.actions;
export default payoutSlice.reducer;