// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PhoneInput input {
  padding: 5px 10px;
  font-weight: 400;
  font-size: 16px;
  border: 1px solid black;
}
.PhoneInput.error input {
  border: 1px solid red;
  outline: none;
}
.PhoneInput.valid input {
  border: 1px solid green;
  outline: none;
}`, "",{"version":3,"sources":["webpack://./src/components/ui/phone-number/styles.scss"],"names":[],"mappings":"AACI;EACI,iBAAA;EACA,gBAAA;EACA,eAAA;EACA,uBAAA;AAAR;AAKQ;EACI,qBAAA;EACA,aAAA;AAHZ;AAOQ;EACI,uBAAA;EACA,aAAA;AALZ","sourcesContent":[".PhoneInput{\n    input{\n        padding: 5px 10px;\n        font-weight: 400;\n        font-size: 16px;\n        border: 1px solid black;\n    }\n\n\n    &.error{\n        input{\n            border: 1px solid red;\n            outline: none;\n        }\n    }\n    &.valid{\n        input{\n            border: 1px solid green;\n            outline: none;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
