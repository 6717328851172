import { IProject } from "config/interfaces";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

interface IUseCheckProjectInstructionResponse {
    isOkey: boolean;
    pageLoading: boolean;
    instructionLink: string | null;
}

export const useCheckProjectInstruction = (active_projects: IProject[]): IUseCheckProjectInstructionResponse => {

    const { project_id } = useParams<{ project_id: string }>();

    const [isOkey, setIsOkey] = useState<boolean>(false);
    const [pageLoading, setPageLoading] = useState<boolean>(true);
    const [instructionLink, setInstructionLink] = useState<string | null>(null);

    useEffect(() => {

        setPageLoading(true)

        return () => {
            clear()
        }
    }, [])


    useEffect(() => {
        let projects_id: number[] = [];

        active_projects.forEach(project => projects_id.push(project.id));


        if (projects_id.includes(Number(project_id))) {
            let current_project = active_projects.find(project => project.id === Number(project_id));
            setInstructionLink(current_project?.guideline_link || null)
            setIsOkey(true);
            setPageLoading(false);
        } else {
            clear()
        }
    }, [active_projects]);


    function clear(): void {
        setInstructionLink(null)
        setPageLoading(false);
        setIsOkey(false);
    }


    return {
        isOkey,
        pageLoading,
        instructionLink
    }
}

