import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "./styles.scss";
export interface IPhoneNumberProps {
    phone: string;
    valid: boolean;
    setPhone: (value: string) => void;
    setValid: (value: boolean) => void;
}

const PhoneNumber = ({
    phone,
    valid,
    setPhone,
    setValid,
}: IPhoneNumberProps): JSX.Element => {
    const { t } = useTranslation();

    useEffect(() => {
        if (phone) {
            setValid(isPossiblePhoneNumber(phone));
        } else {
            setValid(false);
        }
    }, [phone, setValid]);

    return (
        <PhoneInput
            className={valid ? "valid" : "error"}
            placeholder={t("ENTER_PHONE")}
            value={phone}
            onChange={(value: any) => setPhone(value)}
            international
        />
    );
};

export default PhoneNumber;
