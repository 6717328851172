import { useEffect } from "react";
import { Mixpanel } from "./mixpanel";

const MixPanelProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
    useEffect(() => {
        Mixpanel.init();
    }, []);

    return <>{children}</>;
};

export default MixPanelProvider;
