import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { setupStore } from './store/store';
import './i18n'
import TechnikalMeintenance from 'pages/technical-maintenance/technikal-meintenance';
import { MixPanelProvider } from 'services/mixpanel';

export const store = setupStore();

const container = document.getElementById('root');
const root = createRoot(container as HTMLDivElement);
root.render(
    <BrowserRouter>
        <MixPanelProvider>
            <Provider store={store}>
                {process.env.REACT_APP_BUILD_TECH_PAGE === "True" ? <TechnikalMeintenance /> : <App />}
            </Provider>
        </MixPanelProvider>
    </BrowserRouter >
);


reportWebVitals();
