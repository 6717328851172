import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorType, ICheckUserName, IRegistrationResponse, IUser } from "../../config/interfaces";
import {
    loginAsync,
    registrationAsync,
    logoutAsync,
    activationEmailAfterRegistrationAsync,
    resetPasswordSendEmailAsync,
    resetPasswordAsync,
    resendActivationEmailAsync,
    checkUserNameAsync,
    googleAuthAsync,
    getOneTimeTokenAsync
} from './auth-actions';
import { storage } from '../../services/storage';
import { ONE_TIME_TOKEN, ONE_TIME_TOKEN_TIMESTAMP, STATUS_CODE_EMAIL_IS_NOT_ACTIVATED, TOKEN } from "../../config/config";
import { selfAsync } from '../user/user-actions';
import { Mixpanel } from "services/mixpanel";

type reset_pasword_status = 'send' | 'sent' | 'new_password' | 'success';

interface IAuthState {
    isAuth: boolean;
    loading: boolean;
    error: ErrorType;
    registration_success: boolean;
    reset_password: {
        loading: boolean;
        error: ErrorType;
        status: reset_pasword_status
    },
    check_user_name: {
        loading: boolean;
        possible_usernames: string[];
        exists: boolean;
    };
    email: string | null;
    login_error_code: number | null;
    one_time_token: string | null;
}

const INITIAL_STATE: IAuthState = {
    isAuth: storage.get(TOKEN) ? true : false,
    loading: false,
    error: null,
    registration_success: false,
    reset_password: {
        loading: false,
        error: null,
        status: 'send'
    },
    check_user_name: {
        loading: false,
        exists: false,
        possible_usernames: []
    },
    email: '',
    login_error_code: null,
    one_time_token: storage.get(ONE_TIME_TOKEN) || null
}


const authSlice = createSlice({
    name: 'auth',
    initialState: INITIAL_STATE,
    reducers: {
        clearAuth(state): void {
            state.error = null;
            state.registration_success = false;
        },
        tryAgainResetPassword(state): void {
            state.reset_password = {
                loading: false,
                error: null,
                status: 'send'
            }
        },
        changeStatus(state, action: PayloadAction<reset_pasword_status>) {
            state.reset_password.status = action.payload
        },
        clearCheckUserName(state) {
            state.check_user_name.loading = false;
            state.check_user_name.exists = false;
            state.check_user_name.possible_usernames = [];
        },
        logoutSystem(state) {
            state.isAuth = false;
            state.loading = false;
            state.error = null;
            storage.delete(TOKEN)
            storage.delete(ONE_TIME_TOKEN)
            storage.delete(ONE_TIME_TOKEN_TIMESTAMP)
            Mixpanel.reset();
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(registrationAsync.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(registrationAsync.fulfilled, (state, action: PayloadAction<IRegistrationResponse>) => {
                state.isAuth = false
                state.loading = false
                state.error = null
                state.registration_success = true;
                state.email = action.payload.email;
            })
            .addCase(registrationAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.isAuth = false
                state.error = action.payload
                state.email = null;
            })
            .addCase(loginAsync.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(loginAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.isAuth = true;
                state.loading = false;
                state.error = null;
                storage.save(TOKEN, action.payload.key);
            })
            .addCase(loginAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.isAuth = false
                state.error = action.payload?.data_error;
                state.login_error_code = action.payload.error_code === STATUS_CODE_EMAIL_IS_NOT_ACTIVATED ? STATUS_CODE_EMAIL_IS_NOT_ACTIVATED : null;
            })
            .addCase(logoutAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(logoutAsync.fulfilled, (state) => {
                state.isAuth = false;
                state.loading = false;
                state.error = null;
                storage.delete(TOKEN)
                storage.delete(ONE_TIME_TOKEN)
                storage.delete(ONE_TIME_TOKEN_TIMESTAMP)
            })
            .addCase(logoutAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.isAuth = true
                state.error = action.payload
            })
            .addCase(selfAsync.fulfilled, (state, action: PayloadAction<IUser>) => {
                state.isAuth = true;
            })
            .addCase(selfAsync.rejected, (state) => {
                state.isAuth = false;
            })
            // Send email for reset_password
            .addCase(resetPasswordSendEmailAsync.pending, (state) => {
                state.reset_password.loading = true;
            })
            .addCase(resetPasswordSendEmailAsync.fulfilled, (state, action: PayloadAction<IUser>) => {
                state.reset_password.status = "sent";
                state.reset_password.loading = false;
            })
            .addCase(resetPasswordSendEmailAsync.rejected, (state, action: PayloadAction<any>) => {
                state.reset_password.error = action.payload;
                state.reset_password.loading = false;
            })
            // Reset Password
            .addCase(resetPasswordAsync.pending, (state) => {
                state.reset_password.loading = true;
            })
            .addCase(resetPasswordAsync.fulfilled, (state, action: PayloadAction<any>) => {
                state.reset_password.loading = false;
                state.reset_password.status = 'success';
            })
            .addCase(resetPasswordAsync.rejected, (state, action: PayloadAction<any>) => {
                state.reset_password.loading = false;
                state.reset_password.error = action.payload
            })
            // Resend Activation Email
            .addCase(resendActivationEmailAsync.pending, (state) => {
                state.loading = true
                state.error = null
            })
            .addCase(resendActivationEmailAsync.fulfilled, (state) => {
                state.loading = false
                state.error = null;
                state.login_error_code = null
            })
            .addCase(resendActivationEmailAsync.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false
                state.error = action.payload;
                state.login_error_code = null
            })
            // Confirm Registration
            .addCase(activationEmailAfterRegistrationAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(activationEmailAfterRegistrationAsync.fulfilled, (state) => {
                state.loading = false;
            })
            .addCase(activationEmailAfterRegistrationAsync.rejected, (state) => {
                state.loading = false;
                state.isAuth = false
            })
            // Check User Name
            .addCase(checkUserNameAsync.pending, (state) => {
                state.check_user_name.loading = true
            })
            .addCase(checkUserNameAsync.fulfilled, (state, action: PayloadAction<ICheckUserName>) => {
                state.check_user_name.loading = false
                state.check_user_name.exists = action.payload.exists
                state.check_user_name.possible_usernames = action.payload.exists === false ? [] : action.payload.possible_usernames
            })
            .addCase(checkUserNameAsync.rejected, (state) => {
                state.check_user_name.loading = false;
                state.check_user_name.exists = false
                state.check_user_name.possible_usernames = []
            })
            .addCase(googleAuthAsync.pending, (state) => {
                state.loading = true;
            })
            .addCase(googleAuthAsync.fulfilled, (state, action) => {
                state.isAuth = true;
                state.loading = false;
                state.error = null;
                storage.save(TOKEN, action.payload.key);
            })
            .addCase(googleAuthAsync.rejected, (state, action: any) => {
                state.loading = false
                state.isAuth = false
                state.error = action.payload;
            })
            .addCase(getOneTimeTokenAsync.pending, (state) => {
                state.loading = true
            })
            .addCase(getOneTimeTokenAsync.fulfilled, (state, action) => {
                state.loading = false;
                state.one_time_token = action.payload.one_time_token;
                storage.save(ONE_TIME_TOKEN, action.payload.one_time_token);

                const time = process.env.REACT_APP_ONE_TIME_TOKEN_AGE || 36000
                const HOURS = +time / (60 * 60); // to hours
                const timestamp = Date.now() + HOURS * 60 * 60 * 1000; // miliseconds

                localStorage.setItem(ONE_TIME_TOKEN_TIMESTAMP, timestamp.toString());
            })
            .addCase(getOneTimeTokenAsync.rejected, (state, action) => {
                state.loading = false;
                state.one_time_token = null;
                storage.delete(ONE_TIME_TOKEN);
            })
    }
})

const { actions, reducer } = authSlice;
export default reducer;
export const { clearAuth, tryAgainResetPassword, changeStatus, clearCheckUserName,logoutSystem } = actions;