import { createAsyncThunk } from "@reduxjs/toolkit";
import UserService from '../../services/user-service';
import { IFaqCategory, LanguageType } from "../../config/interfaces";
import { Mixpanel, MIXPANEL_TRACK_KEYS } from "services/mixpanel";

export const selfAsync = createAsyncThunk(
    'user/self',
    async (_, thunkApi) => {
        try {
            let response = await UserService.self();
            if(response.id){
                Mixpanel.set_user(response);
                Mixpanel.identify();
            }
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList);
        }
    }
)

export const statusOnboardingAsync = createAsyncThunk(
    'onboarding/status',
    async (value: boolean, thunkApi) => {
        try {
            let response = await UserService.sendStatusOnboarding(value);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const updateUserAsync = createAsyncThunk(
    'user/update',
    async (data: any, thunkApi) => {
        try {
            let response = await UserService.updateUser(data.id, data.values);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const updateUserLanguageAsync = createAsyncThunk(
    'user/update/language',
    async (data: any, thunkApi) => {
        try {
            let response = await UserService.updateLanguage(data);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getFaqListAsync = createAsyncThunk(
    'user/faq',
    async (lang: LanguageType, thunkApi): Promise<IFaqCategory[] | any> => {
        try {
            let response = await UserService.getFAQ(lang);
            Mixpanel.track(MIXPANEL_TRACK_KEYS.FAQ_PAGE, {})
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)