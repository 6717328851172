import { useTranslation } from "react-i18next";
import { notification, Popconfirm } from "antd";
import { PhoneNumber, PlatformBtn } from "components/ui";
import { useState } from "react";
import { useAppDispatch } from "hooks";
import { updateAfterSuccessAlertification, updatePersonalDataAsync } from "store";
import "./styles.scss";

const AccountNotificationActionsTelNumber = ({ id }: { id: number }): JSX.Element => {

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [phone, setPhone] = useState<string>("");
    const [valid, setValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)

    function onSubmit(): void {

        if (phone!.length < 8) {
            notification.error({
                duration: 5,
                message: t("ENTER_PHONE_MESSAGE")
            })
            return
        }
        setLoading(true)
        dispatch(updatePersonalDataAsync({
            id: id,
            data: {
                phone_number: phone,
                alertification: "true"
            }
        }))
            .then((result: any) => {
                if (result.meta.requestStatus === "fulfilled") {
                    dispatch(updateAfterSuccessAlertification("phone_number"))
                    notification.success({
                        duration: 5,
                        message: t("PHONE_SUCCESS")
                    })
                }
            })
            .finally(() => setLoading(false))
    }


    return (
        <div className="acc_notification_tel">
            <h1>{t("ENTER_PHONE")}</h1>
            <p className="acc_notification_tel_description">{t("ENTER_PHONE_DESC")}</p>
            <PhoneNumber
                phone={phone}
                valid={valid}
                setPhone={setPhone}
                setValid={setValid}
            />
            <Popconfirm
                title={t("SURE")}
                onConfirm={onSubmit}
                okText={t('VERIFICATION.STEPS.YES')}
                cancelText={t('VERIFICATION.STEPS.NO')}
            >
                <PlatformBtn loading={loading} disabled={!valid || loading} size="large">{t("BUTTON.SAVE")}</PlatformBtn>
            </Popconfirm>
        </div>
    )
}

export default AccountNotificationActionsTelNumber;

