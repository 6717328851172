import { createAsyncThunk } from "@reduxjs/toolkit";
import { IAvailableJobsParams, IContactUs, IDataTakeJob, LanguageType } from "config/interfaces";
import { AccountService } from "services";
import { Mixpanel, MIXPANEL_TRACK_KEYS } from "services/mixpanel";
import { isReviewer } from "utils";

export const getDashboardAsync = createAsyncThunk(
    'account/getDashboard',
    async (_, thunkApi) => {
        try {
            let response = await AccountService.getDashboard();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const takeJobAsync = createAsyncThunk(
    'account/takeJob',
    async (data: IDataTakeJob, thunkApi) => {
        try {
            let response = await AccountService.takeJob(data);
            if (response) {
                Mixpanel.track(response.draft ? MIXPANEL_TRACK_KEYS.TAKE_PILOT_TASK : MIXPANEL_TRACK_KEYS.TAKE_TASK, {
                    task_id: response.task_id,
                });
            }
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getJobsAsync = createAsyncThunk(
    'account/getJobs',
    async (params: IAvailableJobsParams, { thunkApi, getState }: any) => {
        const state: any = await getState();
        try {
            if (!state.user?.self?.level) {
                return await AccountService.getJobs(params);
            }
            if (isReviewer(state.user.self.level)) {
                return await AccountService.getJobs(params);
            } else {
                return await AccountService.getJobs({ ...params, annotator: true });
            }
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getMyJobsAsync = createAsyncThunk(
    'account/getMyJobs',
    async (page: number = 1, thunkApi) => {
        try {
            let response = await AccountService.myJobs(page);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const checkMyJobAsync = createAsyncThunk(
    'account/checkMyJobAsync',
    async (_, thunkApi) => {
        try {
            let response = await AccountService.getActive();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getJobsHistoryAsync = createAsyncThunk(
    'account/jobs_history',
    async (page: number = 1, thunkApi) => {
        try {
            let response = await AccountService.jobsHistory(page);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const getJobInfoAsync = createAsyncThunk(
    'account/job_info',
    async (data: any, thunkApi) => {
        try {
            let response = await AccountService.jobInfo(data.id, data.historyJob);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const refuseJobAsync = createAsyncThunk(
    'account/refuse',
    async (data: IDataTakeJob, thunkApi) => {
        try {
            let response = await AccountService.takeJob(data);
            if (response) {
                Mixpanel.track(MIXPANEL_TRACK_KEYS.GIVE_UP_TASK, { task_id: response.task_id });
            }
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const contactUsAsync = createAsyncThunk(
    'account/contact_us',
    async (data: IContactUs, thunkApi) => {
        try {
            let response = await AccountService.contact_us(data);
            return response
        } catch (error: any) {
            // let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(error.response.data)
        }
    }
)

export const projectEnrollAsync = createAsyncThunk(
    'account/project_enroll',
    async (id: number, thunkApi) => {
        try {
            let response = await AccountService.project_enrol(id);
            Mixpanel.track(MIXPANEL_TRACK_KEYS.JOIN_PROJECT, { project_id: id });
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)

export const projectUnenrollAsync = createAsyncThunk(
    'account/project_unenroll',
    async (id: number, thunkApi) => {
        try {
            let response = await AccountService.project_unenrol(id);
            if (response) {
                Mixpanel.track(MIXPANEL_TRACK_KEYS.UNASSIGN_FROM_PROJECT, { project_id: id });
            }
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)
export const projectOverviewAsync = createAsyncThunk(
    'account/project_overview',
    async (_, thunkApi) => {
        try {
            let response = await AccountService.project_overview();
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)


export const getNotificationsAsync = createAsyncThunk(
    'account/get_notification',
    async (language: LanguageType, thunkApi) => {
        try {
            let response = await AccountService.get_notifications(language);
            return response
        } catch (error: any) {
            let errorList = Object.values(error.response.data)
            return thunkApi.rejectWithValue(errorList)
        }
    }
)