export enum MIXPANEL_TRACK_KEYS {
    SUBMIT_DOCUMENTS_FOR_VERIFICATION = "SUBMIT_DOCUMENTS_FOR_VERIFICATION",
    SIGN_W8_FORM = "SIGN_W8_FORM",
    NEW_REGISTRATION = "NEW_REGISTRATION",
    LOGIN = "LOGIN",
    TAKE_TASK = "TAKE_TASK",
    TAKE_PILOT_TASK = "TAKE_PILOT_TASK",
    GIVE_UP_TASK = "GIVE_UP_TASK",
    JOIN_PROJECT = "JOIN_PROJECT",
    UNASSIGN_FROM_PROJECT = "UNASSIGN_FROM_PROJECT",
    OPEN_COURSES_PAGE = "OPEN_COURSES_PAGE",
    FAQ_PAGE = "FAQ_PAGE",
    PLAY_VIDEO = "PLAY_VIDEO",
    PAYOUT_REQUESTS = "PAYOUT_REQUESTS",
    SIGN_UP_VISIT = "SIGN_UP_VISIT",
    VERIFICATION_CHANGE_PERSONAL_DATA = "VERIFICATION_CHANGE_PERSONAL_DATA",
    ONBOARDING_AGAIN = "ONBOARDING_AGAIN"
}
