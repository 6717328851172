// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.acc_notification_tel h1 {
  text-align: center;
  margin-bottom: 30px;
}
.acc_notification_tel_description {
  font-size: 15px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 30px;
}
.acc_notification_tel .react-tel-input {
  margin: 0 auto;
  max-width: 300px;
}
.acc_notification_tel .button {
  margin: 50px auto 0;
}`, "",{"version":3,"sources":["webpack://./src/components/account-notification-actions/styles.scss"],"names":[],"mappings":"AACI;EACI,kBAAA;EACA,mBAAA;AAAR;AAGI;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;EACA,mBAAA;AADR;AAII;EACI,cAAA;EACA,gBAAA;AAFR;AAKI;EACI,mBAAA;AAHR","sourcesContent":[".acc_notification_tel{\n    h1{\n        text-align: center;\n        margin-bottom: 30px;\n    }\n\n    &_description{\n        font-size: 15px;\n        font-weight: 400;\n        text-align: center;\n        margin-bottom: 30px;\n    }\n\n    .react-tel-input{\n        margin: 0 auto;\n        max-width: 300px;\n    }\n\n    .button{\n        margin: 50px auto 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
