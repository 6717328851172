import { useEffect, memo } from 'react'
import { useTranslation } from 'react-i18next';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import { useNavigate } from 'react-router-dom';
import { disableRepeatOnboarding, setRun, setStepIndex, statusOnboardingAsync } from 'store';
import { useAppDispatch, useAppSelector, useWindowSize } from 'hooks';
import { I18N, LANGUAGE, PROGRESS, WIDTH_FOR_ONBOARDING } from 'config/config';

const Onboarding: React.FC = (): JSX.Element | null => {

    const { stepIndex, run, repeat_oboarding, steps_ua, steps_en } = useAppSelector(state => state.onboarding);
    const { self } = useAppSelector(state => state.user);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { width } = useWindowSize();
    const { t } = useTranslation()

    const handleJoyrideCallback = (data: any): void => {
        const { action, index, status, type } = data;

        if (action === ACTIONS.SKIP) {
            dispatch(statusOnboardingAsync(true))
            dispatch(disableRepeatOnboarding())
        }

        if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            dispatch(setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)))
        }

        else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            dispatch(setRun(false))
            dispatch(disableRepeatOnboarding())
        }
    };

    const steps = localStorage.getItem(I18N) === LANGUAGE.UA ? steps_ua : steps_en;

    useEffect(() => {
        if (stepIndex === 1) {
            window.scrollTo(0, document.body.scrollHeight);
        }
        if (stepIndex > 1) {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        }
        if (stepIndex === steps.length) {
            dispatch(disableRepeatOnboarding())
            dispatch(statusOnboardingAsync(true))
        }
    }, [stepIndex, dispatch, steps, navigate])

    useEffect(() => {
        if (self?.onboarding === false) {
            dispatch(setRun(true))
        }
    }, [self, dispatch])


    let btnStyles = {
        color: '#fefefe',
        borderRadius: 20,
        fontSize: 16,
        width: 105
    }


    if (!self) {
        return <span>...</span>
    }

    if (width < WIDTH_FOR_ONBOARDING) {
        return null
    }

    return (
        <>
            {(self.onboarding === false || repeat_oboarding === true) && <Joyride
                run={run}
                callback={handleJoyrideCallback}
                stepIndex={stepIndex}
                steps={steps}
                disableOverlay={false}
                disableOverlayClose={true}
                locale={{
                    next: t('ONBOARDING.NEXT'),
                    skip: t('ONBOARDING.SKIP'),
                    back: t('ONBOARDING.PREV'),
                    last: t('ONBOARDING.LAST')
                }}
                scrollToFirstStep={true}
                disableScrolling={true}
                showSkipButton={true}
                continuous={true}
                showProgress={false}
                hideBackButton={true}
                styles={{
                    tooltipTitle: {
                        visibility: "hidden"
                    },
                    buttonNext: {
                        ...btnStyles,
                        backgroundColor: '#00955F',
                    },
                    buttonSkip: {
                        color: 'white',
                        backgroundColor: '#24202E',
                        borderRadius: 20,
                        border: `1px solid ${PROGRESS.strokeColor}`,
                        fontSize: 16,
                        width: 105
                    },
                    options: {
                        arrowColor: '#24202E',
                        backgroundColor: '#24202E',
                        beaconSize: 36,
                        overlayColor: 'rgba(0, 0, 0, 0.3)',
                        primaryColor: 'white',
                        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
                        textColor: '#ffffff',
                        width: undefined,
                        zIndex: 100,
                    }
                }}
            />}
        </>
    )
}

export default memo(Onboarding)
