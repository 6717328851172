import { useRef } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

export interface IPhoneNumberProps {
    phone: string;
    valid: boolean;
    setPhone: (value: string) => void;
    setValid: (value: boolean) => void
}

function removePlusSign(str: string): string {
    if (str.startsWith('+')) {
        return str.slice(1);
    }
    return str;
}

function cleanFormat(format: string): string {
    return removePlusSign(format.replace(/[\s()_-]/g, ''));
}

const CORRECT_FORMAT = ["ua", "pl", "tr", "es", "fr"];


const PhoneNumber = ({ phone, valid, setPhone, setValid }: IPhoneNumberProps): JSX.Element => {

    const { t } = useTranslation();
    const ref = useRef<string>();


    function checkValidNumber(inputNumber: string, country: any): boolean {

        let { format, iso2 } = country;

        let new_format = cleanFormat(format)

        let input_length = CORRECT_FORMAT.includes(iso2) ? 0 : 1

        if ((inputNumber.length + input_length) >= new_format.length) {
            setValid(true);
            return true
        }

        setValid(false);
        return false;
    }


    return (
        <PhoneInput
            country={'ua'}
            value={phone}
            onChange={(inputNumber: string, country: any) => {
                if (!ref.current) {
                    setPhone(inputNumber)
                    ref.current = country.countryCode;
                }
                if (ref.current === country.countryCode) {
                    setPhone(inputNumber)
                } else {
                    setPhone(country.dialCode)
                    ref.current = country.countryCode;
                }
            }}
            isValid={(inputNumber, country) => {
                return checkValidNumber(inputNumber, country)
            }}
            placeholder={t("ENTER_PHONE")}
        />
    )
}

export default PhoneNumber;